import { render, staticRenderFns } from "./ServicesHistory.vue?vue&type=template&id=0511b6d6&"
import script from "./ServicesHistory.vue?vue&type=script&lang=js&"
export * from "./ServicesHistory.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports