<template>
  <div v-if="studentSieId">
    <PageHeader
      has-return
      :page="{
        icon: 'fas fa-user-edit',
        title: 'Historial de Terapias',
        routes: ['Inicio', 'Historial de Terapias'],
      }"
    />

    <StudentServicesHistory :student-sie-id="studentSieId" />
  </div>
</template>

<script>
import PageHeader from 'PageHeader';
import StudentServicesHistory from '@/components/StudentServicesHistory';

export default {
  name: 'ServicesHistory',
  components: { PageHeader, StudentServicesHistory },
  props: { studentSieId: { type: [String, Number], default: null } },
};
</script>
