<template>
  <div v-if="studentSieId">
    <MatchMedia v-slot="{ mobile }">
      <template v-for="(item, i) in items">
        <UiCardListItem
          :key="i"
          bordered-design
          :class="{ 'mt-3': i > 0 }"
          collapse
        >
          <b-row>
            <b-col class="mt-4 mt-md-0" cols="12" md>
              <UiDetailDisplay
                icon="fas fa-ballot-check"
                label="Servicio:"
                :text="item.refService"
              />
            </b-col>
            <b-col class="mt-4 mt-md-0" cols="12" md>
              <template
                v-if="item.__type === DATA_TYPE.REFERRAL_ADMISSION_HISTORY"
              >
                <UiDetailDisplay
                  icon="fas fa-address-book"
                  label="Número de Referido:"
                  :text="item.refRequestNumber"
                />
              </template>
              <template
                v-else-if="
                  item.__type === DATA_TYPE.STUDENT_DISCIPLINES_FOR_REMEDY
                "
              >
                <UiDetailDisplay
                  icon="fas fa-address-book"
                  label="Número de Solicitud:"
                  :text="item.refRequestNumber"
                />
              </template>
            </b-col>
            <b-col class="mt-4 mt-md-0" cols="12" md>
              <template
                v-if="item.__type === DATA_TYPE.REFERRAL_ADMISSION_HISTORY"
              >
                <UiDetailDisplay
                  icon="fas fa-calendar-edit"
                  label="Fecha de Referido:"
                >
                  <template #default="{ placeholder }">
                    <span v-if="item.refRequestDate">
                      {{ item.refRequestDate | moment('DD/MMM/YYYY') }}
                    </span>
                    <span v-else>
                      {{ placeholder }}
                    </span>
                  </template>
                </UiDetailDisplay>
              </template>
              <template
                v-else-if="
                  item.__type === DATA_TYPE.STUDENT_DISCIPLINES_FOR_REMEDY
                "
              >
                <UiDetailDisplay
                  icon="fas fa-calendar-edit"
                  label="Fecha de Solicitud:"
                >
                  <template #default="{ placeholder }">
                    <span v-if="item.refRequestDate">
                      {{ item.refRequestDate | moment('DD/MMM/YYYY') }}
                    </span>
                    <span v-else>
                      {{ placeholder }}
                    </span>
                  </template>
                </UiDetailDisplay>
              </template>
            </b-col>

            <template
              v-if="item.__type === DATA_TYPE.REFERRAL_ADMISSION_HISTORY"
            >
              <b-col class="mt-4 mt-md-0" cols="12" md>
                <UiDetailDisplay
                  icon="fas fa-ballot-check"
                  label="Modalidad:"
                  :text="item.refModality"
                />
              </b-col>
              <b-col class="mt-4 mt-md-0" cols="12" md>
                <UiDetailDisplay
                  icon="fas fa-clock"
                  label="Frecuencia / Duración:"
                >
                  <template #default="{ placeholder }">
                    <span v-if="item.refFrequency && item.refDuration">
                      {{ item.refFrequency }}
                      <span>|</span>
                      {{ item.refDuration }}
                    </span>
                    <span v-else>
                      {{ placeholder }}
                    </span>
                  </template>
                </UiDetailDisplay>
              </b-col>
            </template>
            <template
              v-else-if="
                item.__type === DATA_TYPE.STUDENT_DISCIPLINES_FOR_REMEDY
              "
            >
              <b-col class="mt-4 mt-md-0" cols="12" md>
                <UiDetailDisplay
                  icon="fas fa-ballot-check"
                  label="Estatus:"
                  :text="item.refServiceStatus"
                />
              </b-col>
            </template>
          </b-row>

          <template #collapse>
            <b-card class="bg-light-gray border-0 p-2" no-body>
              <div class="p-2 pb-4 pb-md-2">
                <b-row>
                  <b-col cols="12" md="4">
                    <div
                      :class="[
                        'pl-md-2 py-4 py-md-0 h-100',
                        {
                          'border-right': !mobile,
                          'border-bottom': mobile,
                        },
                      ]"
                    >
                      <b-row>
                        <b-col cols="6" md="12">
                          <div class="d-flex align-items-center h-100">
                            <div>
                              <UiBadge
                                :css-class="[
                                  `bg-${$options.filters.studentEvalutationStatusColor(
                                    item.refStatusId
                                  )}`,
                                ]"
                                :text="item.refStatus"
                                text-class="text-dark text-wrap"
                              />
                            </div>
                          </div>
                        </b-col>
                        <b-col class="pt-md-4" cols="6" md="12">
                          <template
                            v-if="
                              item.__type ===
                                DATA_TYPE.REFERRAL_ADMISSION_HISTORY
                            "
                          >
                            <UiDetailDisplay label="Fecha de Estatus:">
                              <template #default="{ placeholder }">
                                <span v-if="item.refStatusDate">
                                  {{
                                    item.refStatusDate | moment('DD/MMM/YYYY')
                                  }}
                                </span>
                                <span v-else>
                                  {{ placeholder }}
                                </span>
                              </template>
                            </UiDetailDisplay>
                          </template>
                          <template
                            v-else-if="
                              item.__type ===
                                DATA_TYPE.STUDENT_DISCIPLINES_FOR_REMEDY
                            "
                          >
                            <UiDetailDisplay
                              label="Número Contrato:"
                              :text="item.refContratNumber"
                            />
                          </template>
                        </b-col>
                      </b-row>
                    </div>
                  </b-col>
                  <b-col cols="12" md="8">
                    <b-row>
                      <b-col class="mt-4 mt-md-0" cols="12" md="3">
                        <UiDetailDisplay label="Fecha de Comienzo:">
                          <template #default="{ placeholder }">
                            <span v-if="item.refDateFrom">
                              {{ item.refDateFrom | moment('DD/MMM/YYYY') }}
                            </span>
                            <span v-else>
                              {{ placeholder }}
                            </span>
                          </template>
                        </UiDetailDisplay>
                      </b-col>
                      <b-col class="mt-4 mt-md-0" cols="12" md="3">
                        <UiDetailDisplay label="Fecha Final:">
                          <template #default="{ placeholder }">
                            <span v-if="item.refDateTo">
                              {{ item.refDateTo | moment('DD/MMM/YYYY') }}
                            </span>
                            <span v-else>
                              {{ placeholder }}
                            </span>
                          </template>
                        </UiDetailDisplay>
                      </b-col>
                      <b-col class="mt-4 mt-md-0" cols="12" md="6">
                        <UiDetailDisplay
                          inline
                          label="Total de Visitas:"
                          :text="item.refVisitCount"
                        />
                        <div>
                          <UiDetailDisplay :inline="!mobile" label="Min:">
                            <template #default="{ placeholder }">
                              <span v-if="item.refVisitMinDate">
                                {{
                                  item.refVisitMinDate | moment('DD/MMM/YYYY')
                                }}
                              </span>
                              <span v-else>
                                {{ placeholder }}
                              </span>
                            </template>
                          </UiDetailDisplay>
                          <UiDetailDisplay :inline="!mobile" label="Máx:">
                            <template #default="{ placeholder }">
                              <span v-if="item.refVisitMaxDate">
                                {{
                                  item.refVisitMaxDate | moment('DD/MMM/YYYY')
                                }}
                              </span>
                              <span v-else>
                                {{ placeholder }}
                              </span>
                            </template>
                          </UiDetailDisplay>
                        </div>
                      </b-col>
                    </b-row>

                    <b-row>
                      <b-col class="mt-4" cols="12" md>
                        <template
                          v-if="
                            item.__type === DATA_TYPE.REFERRAL_ADMISSION_HISTORY
                          "
                        >
                          <UiDetailDisplay label="Compañia:">
                            <template #default="{ placeholder }">
                              <span v-if="item.refCompany">
                                <dd>{{ item.refCompany | capitalize }}</dd>
                              </span>
                              <span v-else>
                                {{ placeholder }}
                              </span>
                            </template>
                          </UiDetailDisplay>
                        </template>
                        <template
                          v-else-if="
                            item.__type ===
                              DATA_TYPE.STUDENT_DISCIPLINES_FOR_REMEDY
                          "
                        >
                          <UiDetailDisplay
                            label="Proveedor:"
                            :text="item.refProvider"
                          />
                        </template>
                      </b-col>
                      <b-col class="mt-4" cols="12" md>
                        <template
                          v-if="
                            item.__type === DATA_TYPE.REFERRAL_ADMISSION_HISTORY
                          "
                        >
                          <UiDetailDisplay label="Especialista de Admisión:">
                            <template #default="{ placeholder }">
                              <span v-if="item.refAdmissionSpecialist">
                                <dd>
                                  {{ item.refAdmissionSpecialist | capitalize }}
                                </dd>
                              </span>
                              <span v-else>
                                {{ placeholder }}
                              </span>
                            </template>
                          </UiDetailDisplay>
                        </template>
                        <template
                          v-else-if="
                            item.__type ===
                              DATA_TYPE.STUDENT_DISCIPLINES_FOR_REMEDY
                          "
                        >
                          <UiDetailDisplay label="Especialistas">
                            <dd
                              v-for="(specialist, si) in item.refSpecialists"
                              :key="si"
                              :class="{ 'm-0': si > 0 }"
                            >
                              {{ specialist }}
                            </dd>
                          </UiDetailDisplay>
                        </template>
                      </b-col>
                    </b-row>

                    <b-row>
                      <b-col class="mt-4" cols="12" md>
                        <template
                          v-if="
                            item.__type === DATA_TYPE.REFERRAL_ADMISSION_HISTORY
                          "
                        >
                          <UiDetailDisplay label="Lugar de Servicio:">
                            <template #default="{ placeholder }">
                              <span v-if="item.refLocation">
                                <dd class="m-0">
                                  {{ item.refLocation | capitalize }}
                                </dd>
                              </span>
                              <span v-else>
                                {{ placeholder }}
                              </span>
                            </template>
                          </UiDetailDisplay>
                        </template>
                      </b-col>
                      <b-col class="mt-4" cols="12" md>
                        <template
                          v-if="
                            item.__type === DATA_TYPE.REFERRAL_ADMISSION_HISTORY
                          "
                        >
                          <UiDetailDisplay label="Especialista de Servicio:">
                            <template #default="{ placeholder }">
                              <span v-if="item.refAssignmentSpecialist">
                                <dd>
                                  {{
                                    item.refAssignmentSpecialist | capitalize
                                  }}
                                </dd>
                              </span>
                              <span v-else>
                                {{ placeholder }}
                              </span>
                            </template>
                          </UiDetailDisplay>
                        </template>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </div>

              <b-card
                v-if="item.__type === DATA_TYPE.STUDENT_DISCIPLINES_FOR_REMEDY"
                class="bg-white border-0 p-3 m-1"
                no-body
              >
                <b-row>
                  <b-col class="mb-3" cols="12">
                    <UiDetailDisplay
                      icon="fas fa-info-circle"
                      :text-placeholder="false"
                    >
                      <template #label>
                        <strong class="flex-wrap text-dark">
                          Información del Contrato en Remedio Provisional
                        </strong>
                      </template>
                    </UiDetailDisplay>
                  </b-col>

                  <b-col cols="12" md>
                    <UiDetailDisplay
                      label="Año Fiscal:"
                      :text="item.refFiscalYear"
                    />
                  </b-col>
                  <b-col class="mt-4 mt-md-0" cols="12" md>
                    <UiDetailDisplay
                      label="Modalidad:"
                      :text="item.refModality"
                    />
                  </b-col>
                  <b-col class="mt-4 mt-md-0" cols="12" md>
                    <UiDetailDisplay
                      label="Frecuencia:"
                      :text="item.refFrequency"
                    />
                  </b-col>
                  <b-col class="mt-4 mt-md-0" cols="12" md>
                    <UiDetailDisplay
                      label="Duración:"
                      :text="item.refDuration"
                    />
                  </b-col>
                </b-row>
              </b-card>

              <b-card
                v-if="item.__type === DATA_TYPE.REFERRAL_ADMISSION_HISTORY"
                class="bg-white border-0 p-3 m-1"
                no-body
              >
                <b-row>
                  <b-col class="mb-3" cols="12">
                    <UiDetailDisplay
                      icon="fas fa-info-circle"
                      :text-placeholder="false"
                    >
                      <template #label>
                        <strong class="flex-wrap text-dark">
                          Información del Contacto
                        </strong>
                      </template>
                    </UiDetailDisplay>
                  </b-col>

                  <b-col cols="12" md>
                    <UiDetailDisplay label="Nombre del Contacto:">
                      <template #default="{ placeholder }">
                        <span v-if="item.refContactName">
                          <dd>{{ item.refContactName | capitalize }}</dd>
                        </span>
                        <span v-else>
                          {{ placeholder }}
                        </span>
                      </template>
                    </UiDetailDisplay>
                  </b-col>
                  <b-col class="mt-4 mt-md-0" cols="12" md>
                    <UiDetailDisplay label="Número de Teléfono:">
                      {{ item.refContactTelephone | phone }}
                    </UiDetailDisplay>
                  </b-col>
                  <b-col class="mt-4 mt-md-0" cols="12" md>
                    <UiDetailDisplay label="Dirección Física:">
                      <template #default="{ placeholder }">
                        <span v-if="item.refContactAddress">
                          <dd>{{ item.refContactAddress | capitalize }}</dd>
                        </span>
                        <span v-else>
                          {{ placeholder }}
                        </span>
                      </template>
                    </UiDetailDisplay>
                  </b-col>
                </b-row>
              </b-card>
            </b-card>
          </template>
        </UiCardListItem>
      </template>
    </MatchMedia>
  </div>
</template>

<script>
import UiCardListItem from '@/components/ui/UiCardListItem';
import UiDetailDisplay from '@/components/ui/UiDetailDisplay';
import UiBadge from '@/components/ui/UiBadge';
import { MatchMedia } from 'vue-component-media-queries';
import { phone, capitalize } from '@/utils/filters';

import { ThrpyAdmissionApi } from '@/api/thrpy-admission.api';

const thrpyAdmissionApi = new ThrpyAdmissionApi();

const DATA_TYPE = {
  REFERRAL_ADMISSION_HISTORY: 'REFERRAL_ADMISSION_HISTORY',
  STUDENT_DISCIPLINES_FOR_REMEDY: 'STUDENT_DISCIPLINES_FOR_REMEDY',
};

export default {
  name: 'StudentServicesHistory',
  filters: { phone, capitalize },
  components: {
    UiCardListItem,
    UiDetailDisplay,
    UiBadge,
    MatchMedia,
  },
  props: { studentSieId: { type: [String, Number], default: null } },
  data: () => ({
    items: [],
    DATA_TYPE,
  }),
  methods: {
    async getReferralAdmissionHistory() {
      const data = await thrpyAdmissionApi.getReferralAdmissionHistory(
        this.studentSieId
      );
      if (data) {
        this.items.push(
          this.normalizeData(DATA_TYPE.REFERRAL_ADMISSION_HISTORY, data)
        );
      }
    },
    async getStudentDisciplinesForRemedy() {
      const data = await thrpyAdmissionApi.getStudentDisciplinesForRemedy(
        this.studentSieId
      );
      if (data) {
        this.items.push(
          this.normalizeData(DATA_TYPE.STUDENT_DISCIPLINES_FOR_REMEDY, data)
        );
      }
    },
    async getStudentHistoryServices() {
      const data = await thrpyAdmissionApi.getStudentHistoryServices(
        this.studentSieId
      );
      if (data) {
        const { services, remedyServices } = data;
        this.items = [
          ...(services
            ? this.normalizeData(DATA_TYPE.REFERRAL_ADMISSION_HISTORY, services)
            : []),
          ...(remedyServices
            ? this.normalizeData(
                DATA_TYPE.STUDENT_DISCIPLINES_FOR_REMEDY,
                remedyServices
              )
            : []),
        ];
      }
    },
    normalizeData(type, data) {
      return data.map((x) => {
        const {
          disciplineName,
          durationTypeName,
          frequencyTypeName,
          modalityTypeName,
          referralDate,
          referralNumber,
          requestDate,
          requestNumber,
          serviceStatus,
          typeName,
        } = x ?? {};

        const {
          admissionSpecialistFullName,
          admissionStatusId,
          admissionStatusName,
          admissionVisitCount,
          admissionVisitMaxDate,
          admissionVisitMinDate,
          assignmentSpecialistFullName,
          companyName,
          effectiveDateFrom,
          effectiveDateTo,
          lastStatusChanged,
          locationName,
          contactInfo,
          contactAddress,
        } = x?.referralAdmissions?.[0] ?? {};

        const {
          contractFrom,
          contractId,
          contractNumber,
          contractStatus,
          contractTo,
          duration,
          fiscalYear,
          frequency,
          modality,
          provider,
          specialist,
          specialists,
        } = x?.contracts?.[0] ?? {};

        return {
          __type: type,
          // ---
          refFiscalYear: fiscalYear,
          //
          refService: disciplineName || typeName,
          refModality: modalityTypeName || modality,
          refFrequency: frequencyTypeName || frequency,
          refDuration: durationTypeName || duration,
          refServiceStatus: serviceStatus,
          //
          refRequestNumber: referralNumber || requestNumber,
          refRequestDate: referralDate || requestDate,
          // ---
          refStatus: admissionStatusName || contractStatus,
          refStatusId: admissionStatusId || contractId,
          refStatusDate: lastStatusChanged,
          refContratNumber: contractNumber,
          // ---
          refDateFrom: effectiveDateFrom || contractFrom,
          refDateTo: effectiveDateTo || contractTo,
          //
          refVisitCount: admissionVisitCount,
          refVisitMinDate: admissionVisitMinDate,
          refVisitMaxDate: admissionVisitMaxDate,
          //
          refCompany: companyName,
          refLocation: locationName,
          refProvider: provider,
          //
          refAdmissionSpecialist: admissionSpecialistFullName,
          refAssignmentSpecialist: assignmentSpecialistFullName,
          refSpecialists: [specialist, ...(specialists || [])],
          refContactName: contactInfo?.contactName,
          refContactTelephone: contactInfo?.contactTelephone.trim(),
          refContactAddress: contactAddress
            ? `${contactAddress?.address1} ${
                contactAddress?.city
              }, ${contactAddress?.state || ''} ${contactAddress?.zipCode}`
            : '-',
        };
      });
    },
  },
  async created() {
    await this.getStudentHistoryServices();
  },
};
</script>
