var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.studentSieId)?_c('div',[_c('MatchMedia',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var mobile = ref.mobile;
return [_vm._l((_vm.items),function(item,i){return [_c('UiCardListItem',{key:i,class:{ 'mt-3': i > 0 },attrs:{"bordered-design":"","collapse":""},scopedSlots:_vm._u([{key:"collapse",fn:function(){return [_c('b-card',{staticClass:"bg-light-gray border-0 p-2",attrs:{"no-body":""}},[_c('div',{staticClass:"p-2 pb-4 pb-md-2"},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('div',{class:[
                      'pl-md-2 py-4 py-md-0 h-100',
                      {
                        'border-right': !mobile,
                        'border-bottom': mobile,
                      } ]},[_c('b-row',[_c('b-col',{attrs:{"cols":"6","md":"12"}},[_c('div',{staticClass:"d-flex align-items-center h-100"},[_c('div',[_c('UiBadge',{attrs:{"css-class":[
                                ("bg-" + (_vm.$options.filters.studentEvalutationStatusColor(
                                  item.refStatusId
                                ))) ],"text":item.refStatus,"text-class":"text-dark text-wrap"}})],1)])]),_c('b-col',{staticClass:"pt-md-4",attrs:{"cols":"6","md":"12"}},[(
                            item.__type ===
                              _vm.DATA_TYPE.REFERRAL_ADMISSION_HISTORY
                          )?[_c('UiDetailDisplay',{attrs:{"label":"Fecha de Estatus:"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var placeholder = ref.placeholder;
return [(item.refStatusDate)?_c('span',[_vm._v(" "+_vm._s(_vm._f("moment")(item.refStatusDate,'DD/MMM/YYYY'))+" ")]):_c('span',[_vm._v(" "+_vm._s(placeholder)+" ")])]}}],null,true)})]:(
                            item.__type ===
                              _vm.DATA_TYPE.STUDENT_DISCIPLINES_FOR_REMEDY
                          )?[_c('UiDetailDisplay',{attrs:{"label":"Número Contrato:","text":item.refContratNumber}})]:_vm._e()],2)],1)],1)]),_c('b-col',{attrs:{"cols":"12","md":"8"}},[_c('b-row',[_c('b-col',{staticClass:"mt-4 mt-md-0",attrs:{"cols":"12","md":"3"}},[_c('UiDetailDisplay',{attrs:{"label":"Fecha de Comienzo:"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var placeholder = ref.placeholder;
return [(item.refDateFrom)?_c('span',[_vm._v(" "+_vm._s(_vm._f("moment")(item.refDateFrom,'DD/MMM/YYYY'))+" ")]):_c('span',[_vm._v(" "+_vm._s(placeholder)+" ")])]}}],null,true)})],1),_c('b-col',{staticClass:"mt-4 mt-md-0",attrs:{"cols":"12","md":"3"}},[_c('UiDetailDisplay',{attrs:{"label":"Fecha Final:"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var placeholder = ref.placeholder;
return [(item.refDateTo)?_c('span',[_vm._v(" "+_vm._s(_vm._f("moment")(item.refDateTo,'DD/MMM/YYYY'))+" ")]):_c('span',[_vm._v(" "+_vm._s(placeholder)+" ")])]}}],null,true)})],1),_c('b-col',{staticClass:"mt-4 mt-md-0",attrs:{"cols":"12","md":"6"}},[_c('UiDetailDisplay',{attrs:{"inline":"","label":"Total de Visitas:","text":item.refVisitCount}}),_c('div',[_c('UiDetailDisplay',{attrs:{"inline":!mobile,"label":"Min:"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var placeholder = ref.placeholder;
return [(item.refVisitMinDate)?_c('span',[_vm._v(" "+_vm._s(_vm._f("moment")(item.refVisitMinDate,'DD/MMM/YYYY'))+" ")]):_c('span',[_vm._v(" "+_vm._s(placeholder)+" ")])]}}],null,true)}),_c('UiDetailDisplay',{attrs:{"inline":!mobile,"label":"Máx:"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var placeholder = ref.placeholder;
return [(item.refVisitMaxDate)?_c('span',[_vm._v(" "+_vm._s(_vm._f("moment")(item.refVisitMaxDate,'DD/MMM/YYYY'))+" ")]):_c('span',[_vm._v(" "+_vm._s(placeholder)+" ")])]}}],null,true)})],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"mt-4",attrs:{"cols":"12","md":""}},[(
                          item.__type === _vm.DATA_TYPE.REFERRAL_ADMISSION_HISTORY
                        )?[_c('UiDetailDisplay',{attrs:{"label":"Compañia:"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var placeholder = ref.placeholder;
return [(item.refCompany)?_c('span',[_c('dd',[_vm._v(_vm._s(_vm._f("capitalize")(item.refCompany)))])]):_c('span',[_vm._v(" "+_vm._s(placeholder)+" ")])]}}],null,true)})]:(
                          item.__type ===
                            _vm.DATA_TYPE.STUDENT_DISCIPLINES_FOR_REMEDY
                        )?[_c('UiDetailDisplay',{attrs:{"label":"Proveedor:","text":item.refProvider}})]:_vm._e()],2),_c('b-col',{staticClass:"mt-4",attrs:{"cols":"12","md":""}},[(
                          item.__type === _vm.DATA_TYPE.REFERRAL_ADMISSION_HISTORY
                        )?[_c('UiDetailDisplay',{attrs:{"label":"Especialista de Admisión:"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var placeholder = ref.placeholder;
return [(item.refAdmissionSpecialist)?_c('span',[_c('dd',[_vm._v(" "+_vm._s(_vm._f("capitalize")(item.refAdmissionSpecialist))+" ")])]):_c('span',[_vm._v(" "+_vm._s(placeholder)+" ")])]}}],null,true)})]:(
                          item.__type ===
                            _vm.DATA_TYPE.STUDENT_DISCIPLINES_FOR_REMEDY
                        )?[_c('UiDetailDisplay',{attrs:{"label":"Especialistas"}},_vm._l((item.refSpecialists),function(specialist,si){return _c('dd',{key:si,class:{ 'm-0': si > 0 }},[_vm._v(" "+_vm._s(specialist)+" ")])}),0)]:_vm._e()],2)],1),_c('b-row',[_c('b-col',{staticClass:"mt-4",attrs:{"cols":"12","md":""}},[(
                          item.__type === _vm.DATA_TYPE.REFERRAL_ADMISSION_HISTORY
                        )?[_c('UiDetailDisplay',{attrs:{"label":"Lugar de Servicio:"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var placeholder = ref.placeholder;
return [(item.refLocation)?_c('span',[_c('dd',{staticClass:"m-0"},[_vm._v(" "+_vm._s(_vm._f("capitalize")(item.refLocation))+" ")])]):_c('span',[_vm._v(" "+_vm._s(placeholder)+" ")])]}}],null,true)})]:_vm._e()],2),_c('b-col',{staticClass:"mt-4",attrs:{"cols":"12","md":""}},[(
                          item.__type === _vm.DATA_TYPE.REFERRAL_ADMISSION_HISTORY
                        )?[_c('UiDetailDisplay',{attrs:{"label":"Especialista de Servicio:"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var placeholder = ref.placeholder;
return [(item.refAssignmentSpecialist)?_c('span',[_c('dd',[_vm._v(" "+_vm._s(_vm._f("capitalize")(item.refAssignmentSpecialist))+" ")])]):_c('span',[_vm._v(" "+_vm._s(placeholder)+" ")])]}}],null,true)})]:_vm._e()],2)],1)],1)],1)],1),(item.__type === _vm.DATA_TYPE.STUDENT_DISCIPLINES_FOR_REMEDY)?_c('b-card',{staticClass:"bg-white border-0 p-3 m-1",attrs:{"no-body":""}},[_c('b-row',[_c('b-col',{staticClass:"mb-3",attrs:{"cols":"12"}},[_c('UiDetailDisplay',{attrs:{"icon":"fas fa-info-circle","text-placeholder":false},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('strong',{staticClass:"flex-wrap text-dark"},[_vm._v(" Información del Contrato en Remedio Provisional ")])]},proxy:true}],null,true)})],1),_c('b-col',{attrs:{"cols":"12","md":""}},[_c('UiDetailDisplay',{attrs:{"label":"Año Fiscal:","text":item.refFiscalYear}})],1),_c('b-col',{staticClass:"mt-4 mt-md-0",attrs:{"cols":"12","md":""}},[_c('UiDetailDisplay',{attrs:{"label":"Modalidad:","text":item.refModality}})],1),_c('b-col',{staticClass:"mt-4 mt-md-0",attrs:{"cols":"12","md":""}},[_c('UiDetailDisplay',{attrs:{"label":"Frecuencia:","text":item.refFrequency}})],1),_c('b-col',{staticClass:"mt-4 mt-md-0",attrs:{"cols":"12","md":""}},[_c('UiDetailDisplay',{attrs:{"label":"Duración:","text":item.refDuration}})],1)],1)],1):_vm._e(),(item.__type === _vm.DATA_TYPE.REFERRAL_ADMISSION_HISTORY)?_c('b-card',{staticClass:"bg-white border-0 p-3 m-1",attrs:{"no-body":""}},[_c('b-row',[_c('b-col',{staticClass:"mb-3",attrs:{"cols":"12"}},[_c('UiDetailDisplay',{attrs:{"icon":"fas fa-info-circle","text-placeholder":false},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('strong',{staticClass:"flex-wrap text-dark"},[_vm._v(" Información del Contacto ")])]},proxy:true}],null,true)})],1),_c('b-col',{attrs:{"cols":"12","md":""}},[_c('UiDetailDisplay',{attrs:{"label":"Nombre del Contacto:"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var placeholder = ref.placeholder;
return [(item.refContactName)?_c('span',[_c('dd',[_vm._v(_vm._s(_vm._f("capitalize")(item.refContactName)))])]):_c('span',[_vm._v(" "+_vm._s(placeholder)+" ")])]}}],null,true)})],1),_c('b-col',{staticClass:"mt-4 mt-md-0",attrs:{"cols":"12","md":""}},[_c('UiDetailDisplay',{attrs:{"label":"Número de Teléfono:"}},[_vm._v(" "+_vm._s(_vm._f("phone")(item.refContactTelephone))+" ")])],1),_c('b-col',{staticClass:"mt-4 mt-md-0",attrs:{"cols":"12","md":""}},[_c('UiDetailDisplay',{attrs:{"label":"Dirección Física:"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var placeholder = ref.placeholder;
return [(item.refContactAddress)?_c('span',[_c('dd',[_vm._v(_vm._s(_vm._f("capitalize")(item.refContactAddress)))])]):_c('span',[_vm._v(" "+_vm._s(placeholder)+" ")])]}}],null,true)})],1)],1)],1):_vm._e()],1)]},proxy:true}],null,true)},[_c('b-row',[_c('b-col',{staticClass:"mt-4 mt-md-0",attrs:{"cols":"12","md":""}},[_c('UiDetailDisplay',{attrs:{"icon":"fas fa-ballot-check","label":"Servicio:","text":item.refService}})],1),_c('b-col',{staticClass:"mt-4 mt-md-0",attrs:{"cols":"12","md":""}},[(item.__type === _vm.DATA_TYPE.REFERRAL_ADMISSION_HISTORY)?[_c('UiDetailDisplay',{attrs:{"icon":"fas fa-address-book","label":"Número de Referido:","text":item.refRequestNumber}})]:(
                item.__type === _vm.DATA_TYPE.STUDENT_DISCIPLINES_FOR_REMEDY
              )?[_c('UiDetailDisplay',{attrs:{"icon":"fas fa-address-book","label":"Número de Solicitud:","text":item.refRequestNumber}})]:_vm._e()],2),_c('b-col',{staticClass:"mt-4 mt-md-0",attrs:{"cols":"12","md":""}},[(item.__type === _vm.DATA_TYPE.REFERRAL_ADMISSION_HISTORY)?[_c('UiDetailDisplay',{attrs:{"icon":"fas fa-calendar-edit","label":"Fecha de Referido:"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var placeholder = ref.placeholder;
return [(item.refRequestDate)?_c('span',[_vm._v(" "+_vm._s(_vm._f("moment")(item.refRequestDate,'DD/MMM/YYYY'))+" ")]):_c('span',[_vm._v(" "+_vm._s(placeholder)+" ")])]}}],null,true)})]:(
                item.__type === _vm.DATA_TYPE.STUDENT_DISCIPLINES_FOR_REMEDY
              )?[_c('UiDetailDisplay',{attrs:{"icon":"fas fa-calendar-edit","label":"Fecha de Solicitud:"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var placeholder = ref.placeholder;
return [(item.refRequestDate)?_c('span',[_vm._v(" "+_vm._s(_vm._f("moment")(item.refRequestDate,'DD/MMM/YYYY'))+" ")]):_c('span',[_vm._v(" "+_vm._s(placeholder)+" ")])]}}],null,true)})]:_vm._e()],2),(item.__type === _vm.DATA_TYPE.REFERRAL_ADMISSION_HISTORY)?[_c('b-col',{staticClass:"mt-4 mt-md-0",attrs:{"cols":"12","md":""}},[_c('UiDetailDisplay',{attrs:{"icon":"fas fa-ballot-check","label":"Modalidad:","text":item.refModality}})],1),_c('b-col',{staticClass:"mt-4 mt-md-0",attrs:{"cols":"12","md":""}},[_c('UiDetailDisplay',{attrs:{"icon":"fas fa-clock","label":"Frecuencia / Duración:"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var placeholder = ref.placeholder;
return [(item.refFrequency && item.refDuration)?_c('span',[_vm._v(" "+_vm._s(item.refFrequency)+" "),_c('span',[_vm._v("|")]),_vm._v(" "+_vm._s(item.refDuration)+" ")]):_c('span',[_vm._v(" "+_vm._s(placeholder)+" ")])]}}],null,true)})],1)]:(
              item.__type === _vm.DATA_TYPE.STUDENT_DISCIPLINES_FOR_REMEDY
            )?[_c('b-col',{staticClass:"mt-4 mt-md-0",attrs:{"cols":"12","md":""}},[_c('UiDetailDisplay',{attrs:{"icon":"fas fa-ballot-check","label":"Estatus:","text":item.refServiceStatus}})],1)]:_vm._e()],2)],1)]})]}}],null,false,863324903)})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }