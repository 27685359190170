import { http } from '@/core/api';

class ThrpyAdmissionApi {
  async getReferralAdmissionHistory(studentSieId) {
    try {
      const { data } = await http.get(
        `/api/thrpyadmission/referraladmissionhistory/${studentSieId}`
      );
      return data;
    } catch {
      return null;
    }
  }

  async getStudentDisciplinesForRemedy(studentSieId) {
    try {
      const { data } = await http.get(
        `/api/thrpyadmission/studentdisciplinesforremedy/${studentSieId}`
      );
      return data;
    } catch {
      return null;
    }
  }

  async getStudentHistoryServices(studentSieId) {
    try {
      const { data } = await http.get(
        `/api/thrpyadmission/studenthistoryservices/${studentSieId}`
      );
      return data;
    } catch {
      return null;
    }
  }
}

export { ThrpyAdmissionApi };
